import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { FedevAuthService } from '@bmw-fedev/auth';

@Injectable()
export class AutoLogoutInterceptor implements HttpInterceptor {
  constructor(private fedevAuthService: FedevAuthService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.fedevAuthService.logout();
          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }
}
