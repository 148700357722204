import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, makeEnvironmentProviders } from '@angular/core';
import { OAuthService, provideOAuthClient } from 'angular-oauth2-oidc';
import { BehaviorSubject, filter, skip, take, distinctUntilChanged } from 'rxjs';
import { Router } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Location } from '@angular/common';

/**
 * Injection token for the Fedev authentication configuration.
 *
 * This token is used to inject the configuration object for the `FedevAuthService`.
 * It allows the configuration to be provided and accessed throughout the application wherever the `FEDEV_AUTH_CONFIG` token is injected.
 *
 * @type {InjectionToken<Type<FedevAuthService>>}
 */
const FEDEV_AUTH_CONFIG = new InjectionToken('FEDEV_AUTH_CONFIG');
/**
 * Injection token for providing authentication initialization options.
 *
 * This token is used to inject options into the authentication initialization factory function (`authInitializerFactory`).
 * The options supplied via this token configure various aspects of the authentication process.
 *
 * @example
 * ```Typescript
 * providers: [
 *  {
 *      provide: FEDEV_AUTH_INIT_OPTIONS,
 *      useValue: {
 *          config: { ... } // for example
 *      }
 *  }
 * ]
 * ```
 *
 * @type {InjectionToken<IFedevAuthInitOptions>}
 * @see IFedevAuthInitOptions for the available options.
 */
const FEDEV_AUTH_INIT_OPTIONS = new InjectionToken('FEDEV_AUTH_INIT_OPTIONS');
const baseConfig = {
  env: {
    production: false,
    clientId: 'empty',
    issuer: 'empty'
  },
  loginRequired: true
};
class FedevAuthService {
  constructor() {
    /**
     * A BehaviorSubject that holds the user's information or `undefined` if not set.
     *
     * This observable emits the user's information in the format `given_name family_name (departmentnumber)` once available. Initially, it is set to `undefined`.
     *
     * @example
     * authService.currentUser$.subscribe((userInfo) => {
     *   console.log(`Current user: ${userInfo}`);
     * });
    */
    this.currentUser$ = new BehaviorSubject(undefined);
    /**
     * A BehaviorSubject that holds the user's information.
     *
     * This observable emits a complete user profile object. Initially, it emits an empty.
     *
     * @example
     * authService.userProfile$.subscribe((userProfile) => {
     *   console.log(`User profile: ${userProfile}`);
     * });
    */
    this.userProfileSubject = new BehaviorSubject({});
    this.userProfile$ = this.userProfileSubject.asObservable().pipe(filter(profile => Object.keys(profile).length > 0));
    this.oAuthService = inject(OAuthService);
    this.rootConfig = inject(FEDEV_AUTH_CONFIG);
    this.router = inject(Router);
    this.userSetRedirectUri = false;
    this.serviceHasLoaded$ = new BehaviorSubject(false);
    this._config = {
      ...baseConfig,
      ...this.rootConfig
    };
    this.oauthConfig = {
      // Url of the Identity Provider
      issuer: this.config.env.issuer,
      // URL of the SPA to redirect the user to after login
      redirectUri: 'https://' + window.location.hostname,
      // The SPA's id. The SPA is registered with this id at the auth-server
      clientId: this.config.env.clientId,
      responseType: 'code',
      // set the scope for the permissions the client should request
      // The first three are required for the Code Flow with PKCE
      scope: 'openid profile email',
      skipIssuerCheck: false,
      oidc: true,
      showDebugInformation: false,
      strictDiscoveryDocumentValidation: false,
      callDiscoveryDocument: true
    };
    setTimeout(() => {
      this.initService();
    }, 10);
  }
  /**
   * Gets the configuration for the Fedev auth provider.
   *
   * @returns {IFedevAuthProviderConfig} The configuration object.
  */
  get config() {
    return this._config;
  }
  /**
   * Gets the access token from the oAuthService.
   *
   * @returns {string} The access token.
  */
  get accessToken() {
    return this.oAuthService.getAccessToken();
  }
  /**
   * Gets the id token from the oAuthService.
   *
   * @returns {string} The id token.
  */
  get idToken() {
    return this.oAuthService.getIdToken();
  }
  async initService() {
    const possibleTarget = this.getAndResetRedirect();
    await this.prepareForLogin();
    const userIsAuthenticated = this.isUserAuthenticated();
    this.serviceHasLoaded$.pipe(skip(1), take(1), distinctUntilChanged()).subscribe(hasLoaded => {
      if (hasLoaded && userIsAuthenticated) {
        this.updateUserInfo();
      }
    });
    if (possibleTarget !== null && userIsAuthenticated) {
      this.router.navigateByUrl(possibleTarget);
    }
    this.serviceHasLoaded$.next(true);
  }
  setRedirectUrl(url) {
    const cleaned = url.replace(/\/$/, '');
    window.sessionStorage.setItem('fedev-auth-redirect', cleaned);
  }
  getAndResetRedirect() {
    const local = window.sessionStorage.getItem('fedev-auth-redirect');
    window.sessionStorage.removeItem('fedev-auth-redirect');
    return local;
  }
  async prepareForLogin() {
    // If running locally and user has not set a redirectUri, use localhost as the redirectUri
    if (!this.userSetRedirectUri && window.location.hostname.startsWith('localhost')) {
      this.oauthConfig.redirectUri = window.location.origin;
    }
    this.oAuthService.configure(this.oauthConfig);
    if (this.oauthConfig.callDiscoveryDocument) {
      await this.oAuthService.loadDiscoveryDocumentAndTryLogin(); // Loads discovery document and tries to login if it already has a valid nonce
    } else {
      await this.oAuthService.tryLogin();
    }
  }
  /**
   * Updates the OAuth configuration and applies it to the OAuth service.
   *
   * Merges the provided configuration with the existing OAuth settings, then configures the OAuth service. If a new redirect URI is provided, it marks the redirect URI as user-defined.
   *
   * @param {Partial<AuthConfig>} [config] - A partial configuration object to merge with the current OAuth settings.
   * @returns {void}
  */
  updateOAuthConfig(config) {
    this.oauthConfig = {
      ...this.oauthConfig,
      ...config
    };
    this.oAuthService.configure(this.oauthConfig); // Configures the OAuth service that is used by angular-oauth2-oidc
    if (config?.redirectUri) {
      this.userSetRedirectUri = true;
    }
  }
  /**
    * Attempts to log the user in by initiating the authorization code flow.
    *
    * This method optionally sets a redirect URL, prepares the service for login, and then starts the OAuth2 authorization code flow.
    *
    * @param {string} [loopbackUrl] - Optional URL to redirect the user to after login.
    * @returns {Promise<void>} A promise that resolves when the login process is initiated.
  */
  async tryLogin(loopbackUrl) {
    if (loopbackUrl) {
      this.setRedirectUrl(loopbackUrl);
    }
    this.prepareForLogin();
    this.oAuthService.initCodeFlow(); // Starts the authorization code flow, adds the PKCE_verifier and nonce to the session storage and redirects to the WebEAM.Next login page
  }
  /**
    * Logs out the user by removing all stored tokens.
    *
    * @returns {void} This method does not return a value.
  */
  logout() {
    // Removes stored tokens and calls the endSession endpoint to complete the logout
    this.oAuthService.logOut();
  }
  /**
    * This method fetches the user's profile information, and updates the `currentUser$` and `userProfile$` observables.
    *
    * @deprecated Replaced by updateUserInfo. Will be removed in the beginning of 2025.
    * @returns {Promise<void>} A promise that resolves when the user information is updated.
  */
  async getUserInfo() {
    await this.updateUserInfo();
  }
  /**
   * This method fetches the user's profile information, and updates the `currentUser$` and `userProfile$` observables.
   *
   * @returns {Promise<void>} A promise that resolves when the user information is updated.
  */
  async updateUserInfo() {
    // Refreshing the page causes the Discovery Document to be lost and needs to be loaded again
    if (!this.oAuthService.userinfoEndpoint) {
      await this.oAuthService.loadDiscoveryDocument();
    }
    // Checks if userInfo was already fetched, if so there's no need to fetch it again.
    if (Object.keys(this.userProfileSubject.getValue()).length === 0) {
      // Call to the "userInfo" endpoint. In this example we're only requesting the attributes "given_name", "family_name" and "departmentnumber".
      // For more available attributes, please check the column "OIDC claims" in https://atc.bmwgroup.net/confluence/display/WEBEAM/WEN+OpenID+Connect+%28OIDC%29+-+Integration+Guide#WENOpenIDConnect(OIDC)IntegrationGuide-LAASAttributes
      const userProfile = await this.oAuthService.loadUserProfile();
      if (userProfile) {
        this.currentUser$.next(`${userProfile.info['given_name']} ${userProfile.info['family_name']} (${userProfile.info['departmentnumber']})`);
        this.userProfileSubject.next(userProfile.info);
      }
    }
  }
  /**
    * Checks if the user is authenticated by verifying the validity of the ID and access tokens.
    * @returns {boolean} True if the user is authenticated, otherwise false.
  */
  isUserAuthenticated() {
    return this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken();
  }
  /**
    * Waits for the service to complete its initialization.
    *
    * This method returns a promise that resolves once the service has finished loading. If the service is already initialized, the promise resolves immediately.
    *
    * @returns {Promise<void>} A promise that resolves when the service initialization is complete.
  */
  async waitForServiceInit() {
    return new Promise(resolve => {
      if (this.serviceHasLoaded$.getValue() === true) {
        resolve();
        return;
      }
      this.serviceHasLoaded$.pipe(skip(1), take(1), distinctUntilChanged()).subscribe(hasLoaded => {
        if (hasLoaded) {
          resolve();
        }
      });
    });
  }
  refreshToken() {
    this.oAuthService.refreshToken();
  }
  static {
    this.ɵfac = function FedevAuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FedevAuthService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FedevAuthService,
      factory: FedevAuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FedevAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/**
 * Provides the necessary environment providers for the Fedev authentication service.
 *
 * This function configures and returns the required providers based on the given configuration.
 * It includes the HTTP client and optionally configures the OAuth client to include the access token in backend requests if specified in the configuration.
 *
 * Additionally, it provides the Fedev authentication configuration and the `FedevAuthService` for dependency injection throughout the application.
 *
 * @param {Partial<IFedevAuthProviderConfig>} config - The configuration object for the Fedev authentication service.
 * @returns {EnvironmentProviders} - A set of environment providers configured for Fedev authentication.
 */
function provideFedevAuth(config) {
  return makeEnvironmentProviders([provideHttpClient(withInterceptorsFromDi()), config.includeAccessTokenInBackendRequests ? provideOAuthClient({
    resourceServer: {
      sendAccessToken: true
    }
  }) : provideOAuthClient(), {
    provide: FEDEV_AUTH_CONFIG,
    useValue: config
  }, {
    provide: FedevAuthService
  }]);
}

/**
 * Auth guard function to determine whether the user is allowed to activate a route.
 *
 * This function first checks if login is required based on the service configuration.
 * If login is not required, the route is allowed without further checks.
 * If login is required, it initializes the authentication service, checks if the user is authenticated, and attempts to log them in if they are not.
 * If the user is authenticated, it updates their information and allows the route activation.
 * Depending on the authentication status, it either allows the route or prevents access until the user logs in.
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the route can be activated or `false` if the user needs to log in.
 */
const authGuardFunction = async () => {
  const authService = inject(FedevAuthService);
  const router = inject(Router);
  const loopbackUrl = router.getCurrentNavigation()?.finalUrl?.toString();
  if (!authService.config.loginRequired) {
    return true;
  }
  try {
    await authService.waitForServiceInit();
    const userIsAuthenticated = authService.isUserAuthenticated();
    if (!userIsAuthenticated) {
      await authService.tryLogin(loopbackUrl || '/');
    }
    return userIsAuthenticated;
  } catch (error) {
    return false;
  }
};

/**
 * Factory function for initializing the authentication process.
 *
 * Used with `APP_INITIALIZER` to set up authentication during the application start, by configuring
 * the Fedev auth service, checking user authentication status and redirecting to the login page
 * if necessary
 *
 * @example
 * Example 1: using default options
 * ```Typescript
 * providers: [
 *  {
 *      provide: APP_INITIALIZER,
 *      useFactory: authInitializerFactory,
 *      deps: [FedevAuthService],
 *      multi: true
 *  }
 * ]
 * ```
 *
 * @example
 * Example 2: using custom options
 * ```Typescript
 * providers: [
 *  {
 *      provide: APP_INITIALIZER,
 *      useFactory: authInitializerFactory,
 *      deps: [FedevAuthService, FEDEV_AUTH_INIT_OPTIONS],
 *      multi: true
 *  },
 *  {
 *      provide: FEDEV_AUTH_INIT_OPTIONS,
 *      useValue: {
 *          config: { ... } // for example
 *      }
 *  }
 * ]
 * ```
 *
 * @param {FedevAuthService} fedevAuthService Service responsible for handling the authentication
 * @param {IFedevAuthInitOptions} [options] Optional configuration options for initializing authentication
 * @returns {Promise<void>} A function that returns a promise, resolving once authentication has been completed
 *
 * @see `IFedevAuthInitOptions` for available options
 */
function authInitializerFactory(fedevAuthService, options) {
  const location = inject(Location);
  return () => new Promise(resolve => {
    if (options?.config) {
      fedevAuthService.updateOAuthConfig(options.config);
    }
    fedevAuthService.waitForServiceInit().then(() => {
      const userIsAuthenticated = fedevAuthService.isUserAuthenticated();
      if (!userIsAuthenticated) {
        fedevAuthService.tryLogin(location.path(true) || '/');
        return;
      }
      resolve();
    });
  });
}

/*
 * Public API Surface of auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FEDEV_AUTH_CONFIG, FEDEV_AUTH_INIT_OPTIONS, FedevAuthService, authGuardFunction, authInitializerFactory, provideFedevAuth };
