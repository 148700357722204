import { Injectable } from '@angular/core';
import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  catchError,
  map,
  Observable,
  of,
  pipe,
  startWith,
  UnaryFunction,
} from 'rxjs';
import { z } from 'zod';
import { PricingApiError } from 'src/app/core/model/pricingApiError';
import { ApiResult } from 'src/app/core/model/apiResult';
import { pricingApiErrorBodySchema } from 'src/data-contract/schemas/pricingApiErrorBodySchema';

export type OptionsWithJsonBody =
  | {
      context?: HttpContext;
      headers?: HttpHeaders | { [header: string]: string | string[] };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  | undefined;

type MethodWithResponseSchema<T> = T extends (
  ...params: infer U
) => Observable<unknown>
  ? <SchemaType extends z.ZodTypeAny, Type = z.infer<SchemaType>>(
      validationSchema: SchemaType,
      ...params: U
    ) => Observable<ApiResult<Type, Error>>
  : never;

interface ValidatingHttpClientMethods<T extends HttpClient = HttpClient> {
  get: MethodWithResponseSchema<T['get']>;
  post: MethodWithResponseSchema<T['post']>;
  put: MethodWithResponseSchema<T['put']>;
}

@Injectable({
  providedIn: 'root',
})
export class ValidatingHttpClient implements ValidatingHttpClientMethods {
  constructor(private httpClient: HttpClient) {}

  get<SchemaType extends z.ZodTypeAny, Type = z.infer<SchemaType>>(
    validationSchema: SchemaType,
    url: string,
    options?: OptionsWithJsonBody
  ): Observable<ApiResult<Type, Error>> {
    return this.httpClient
      .get<Type>(url, options)
      .pipe(this.mapToApiResult(validationSchema));
  }

  put<SchemaType extends z.ZodTypeAny, Type = z.infer<SchemaType>>(
    validationSchema: SchemaType,
    url: string,
    body: unknown,
    options?: OptionsWithJsonBody
  ): Observable<ApiResult<Type, Error>> {
    return this.httpClient
      .put<Type>(url, body, options)
      .pipe(this.mapToApiResult(validationSchema));
  }

  post<SchemaType extends z.ZodTypeAny, Type = z.infer<SchemaType>>(
    validationSchema: SchemaType,
    url: string,
    body: unknown,
    options?: OptionsWithJsonBody
  ): Observable<ApiResult<Type, Error>> {
    return this.httpClient
      .post<Type>(url, body, options)
      .pipe(this.mapToApiResult(validationSchema));
  }

  private mapToApiResult = <
    SchemaType extends z.ZodTypeAny,
    Type = z.infer<SchemaType>,
  >(
    schema: SchemaType
  ): UnaryFunction<Observable<Type>, Observable<ApiResult<Type, Error>>> =>
    pipe(
      map((response: unknown): ApiResult<Type, Error> => {
        const parseResult = schema.safeParse(response);

        return parseResult.success
          ? ApiResult.ok(parseResult.data)
          : ApiResult.error(parseResult.error);
      }),
      startWith(ApiResult.loading()),
      catchError((error: unknown): Observable<ApiResult<Type, Error>> => {
        if (error instanceof HttpErrorResponse) {
          const pricingApiErrorParsed = pricingApiErrorBodySchema.safeParse(
            error.error
          );

          if (pricingApiErrorParsed.success) {
            return of(
              ApiResult.error(
                new PricingApiError(error, pricingApiErrorParsed.data)
              )
            );
          }

          return of(ApiResult.error(error));
        }

        return of(
          ApiResult.error(
            new Error(`Request failed with unknown error ${error}`)
          )
        );
      })
    );
}
