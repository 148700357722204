import { HttpErrorResponse } from '@angular/common/http';
import { PricingApiErrorBody } from 'src/data-contract/schemas/pricingApiErrorBodySchema';

export class PricingApiError extends HttpErrorResponse {
  code: string;
  title: string;
  detail: string;

  constructor(error: HttpErrorResponse, body: PricingApiErrorBody) {
    super({
      ...error,
      url: error.url ?? undefined,
    });

    this.code = body.code;
    this.title = body.title;
    this.detail = body.detail;
  }
}
