import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    loadComponent: () =>
      import(
        'src/app/pricing/components/pricing-page/pricing-page.component'
      ).then((c) => c.PricingPageComponent),
    path: 'vehicles',
  },
  {
    loadComponent: () =>
      import(
        'src/app/pricing/components/details-page/details-page.component'
      ).then((c) => c.DetailsPageComponent),
    path: 'vehicles/:vin',
  },
  { path: '', pathMatch: 'full', redirectTo: 'vehicles' },
  { path: '**', redirectTo: 'vehicles' },
];
